<template>
  <div class="flex min-h-svh flex-col">
    <div class="pointer-events-none fixed bottom-0 left-0 z-0 w-full">
      <MediaPicture
        v-show="localePath($route) !== localePath('index')"
        :src="BottomMatrix"
        :width="1372"
        :height="388"
        transition-off
      />
      <MatrixCanvas v-show="localePath($route) === localePath('index')" />
    </div>
    <Header
      :class="['shrink-0 grow-0', fixedHeader ? 'fixed' : 'sticky top-0']"
      :guest="guest"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import BottomMatrix from '~/assets/images/BottomMatrix.svg';
import Header from '~/components/Header/Header.vue';
import MediaPicture from '~/components/Media/MediaPicture.vue';
import MatrixCanvas from '~/components/Misc/MatrixCanvas.vue';

defineProps<{ fixedHeader?: boolean; guest?: boolean }>();
const localePath = useLocalePath();
// const attrs = useAttrs();
</script>
