<template>
  <header :class="['z-header w-full rfs:py-50', $style.el]">
    <div class="wrapper-fluid flex items-center justify-between rfs:gap-28">
      <NuxtLink
        class="flex items-center py-3 leading-normal rfs:gap-18"
        :href="localePath('/')"
      >
        <LogoIcon class="shrink-0 text-primary rfs:h-44 rfs:w-40" />
        <span class="flex items-center gap-6 font-bold">
          <span class="rfs:text-30">GIO</span>
          <strong class="text-gray-535353 rfs:text-34">Pulse</strong>
        </span>
      </NuxtLink>
      <div
        v-if="!guest"
        class="flex items-center font-bold rfs:gap-28 rfs:text-16"
      >
        <template v-if="connected">
          <div class="hidden shrink-0 items-center gap-14 md:flex">
            <TokenIcon class="size-30 fill-black" />
            {{ Number(balance).toFixed(3) }} GIO
          </div>
          <div
            class="hidden shrink-0 cursor-pointer items-center rfs:gap-14 md:flex"
            @click="open()"
          >
            <div
              class="size-30 shrink-0 rounded-full border-8 border-primary bg-black"
            ></div>
            {{
              `${currentAddress?.substring(0, 7)}...${currentAddress?.substring(39, 42)}`
            }}
          </div>
          <button
            class="btn btn-primary btn-with-icon shrink-0 rfs:px-14 lg:rfs:px-30"
            type="button"
            @click="router.push('/nodes')"
          >
            <CoinUnbrokenIcon class="size-24" />
            <span class="hidden lg:inline">MY TOKENS</span>
          </button>
        </template>
        <button
          v-else
          class="btn btn-primary relative rfs:px-14"
          @click="open()"
        >
          CONNECT MY WALLET
        </button>
        <button
          class="btn btn-outline-success btn-with-icon shrink-0 rfs:px-14"
          type="button"
        >
          <GreenServerIcon class="size-24 fill-success" />
          <span class="hidden xl:inline">Green Server CO2 Connected</span>
          <span class="hidden size-6 rounded-full bg-success xl:inline"></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { formatEther } from 'viem';
import { watchAccount, getAccount, getBalance, type Config } from '@wagmi/core';
import { useWeb3Modal } from '@web3modal/wagmi/vue';
import CoinUnbrokenIcon from '~/icons/CoinUnbrokenIcon.vue';
import GreenServerIcon from '~/icons/GreenServerIcon.vue';
import LogoIcon from '~/icons/LogoIcon.vue';
import TokenIcon from '~/icons/TokenIcon.vue';

defineProps<{ guest?: boolean }>();

const router = useRouter();
const { open } = useWeb3Modal();
const wagmiConfig = inject('wagmiConfig');
const balance = ref('');
const connected = ref(false);
const newAddress = ref('');

const { address } = getAccount(wagmiConfig as Config);
const localePath = useLocalePath();

const currentAddress = computed(() => address || newAddress.value);

const setupData = async () => {
  if (address) {
    const rawBalance = await getBalance(wagmiConfig as Config, {
      address,
    });
    balance.value = formatEther(rawBalance?.value);
    connected.value = true;
  }
};

watchAccount(wagmiConfig as Config, {
  onChange(data) {
    if (data.status === 'connected') {
      connected.value = true;
      newAddress.value = data.address;
      setupData();
    } else {
      connected.value = false;
    }
  },
});

onMounted(() => {
  setupData();
});
</script>

<style module>
@keyframes toBlack {
  to {
    @apply rfs:py-20 bg-black;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  }
}

.el {
  animation: toBlack linear both;
  animation-timeline: scroll();
  animation-range: 0 5rem;
}
</style>
